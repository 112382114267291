import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Mh3 = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #B3054F, #C70256, #D4005A, #C70256, #B3054F )",
        icons: "#fff",
        navClass: "mh3",
      }}
      seo={{
        title: "Historical Cities 3.0",
        headerTitle: "mh30",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/mh30_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/mh-3-0/",
      }}
    >
      <PortfolioHeader name="mh30" height="189" />
      <section className="container-fluid mh30_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Historical Cities 3.0</h1>
              <ul>
                <li>Logo</li>
                <li>Branding</li>
                <li>Advertising materials</li>
                <li>Website</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Historical Cities 3.0 is an international conference organized
                by the City of Krakow. We have prepared a full visual identity
                of the project as well as informative and promotional materials
                for the first edition of this international conference. The
                effect of our work was, among others: full identification with
                Brand Book and POS (Point of Sale) materials. We were also
                responsible for developing the functional layout, graphic design
                and coding both front-end and back-end of the conferences’
                website. Since then, each year we prepare the website for the
                next editions of the event.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid mh30_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/mh30_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/mh30_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Magenta CMYK</p>
                    <h5>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 25 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 8 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Gold CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 22 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 28 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 62 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 1 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 216 : 0}
                      />
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 91 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 203 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 176 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 118 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>d8005b</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>cbb076</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mh30_section_4">
        <h4>Advertising materials</h4>
        <img
          src={require("../../../assets/img/portfolio/mh30_main_4.png")}
          alt=""
        />
      </section>
      <section className="mh30_section_5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="inner">
              <h4>Website</h4>
              <p>
                We have developed the website’s layout and its graphic design
                (based on the visual identity of the conference that we have
                designed earlier). The main goal of the implementation was to
                present the conference agenda and its speakers.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  className="img-fluid"
                  src={require("../../../assets/img/portfolio/mh30_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mh30_section_6">
        <img
          className="img-fluid"
          src={require("../../../assets/img/portfolio/mh30_main_6.png")}
          alt=""
        />
      </section>
      <section className="mh30_section_7">
        <img
          className="img-fluid"
          src={require("../../../assets/img/portfolio/mh30_main_7.png")}
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Mh3;
